<template>
  <div class="surgeryAppointment">
    <div class="title">
      <span>手术预约</span>
      <i @click="gb" class="el-icon-close"></i>
    </div>

    <div class="box">
      <div class="left">
        <div class="left-a">
          <el-select class="xm" disabled  v-model="value2"  placeholder="姓名">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-input class="bh" v-model="value2" disabled ></el-input>
          <el-button disabled style="background: #F7F7F7">建档</el-button>
        </div>
        <div class="yhxx">
          <img src="../../assets/img/mrtx.png" alt="">
          <div style="padding: 0 20px;width: 150px;">
            <p>
              <span>姓名：</span>
              {{csdata.CustomerName}}
            </p>
            <p>
              <span>性别：</span>
              {{csdata.CustomerGender==1?'男':'女'}}
            </p>
            <p>
              <span>手机号：</span>
              {{csdata.CustomerTel}}
            </p>
            <p>
              <span>就诊医生：</span>
              {{postdata.ExpertName}}
            </p>
          </div>
          <div>
            <p>
              <span>患 者 ID：</span>
              {{csdata.CustomerId.slice(0,8).toUpperCase()}}
            </p>
            <p>
              <span>出生日期：</span>
              {{csdata.CustomerBirthday}}
            </p>
            <p>
              <span>建档日期：</span>
              {{csdata.CustomerAddTime.split(' ')[0]}}
            </p>
            <p>
              <span>就诊日期：</span>
              {{today}}
            </p>
          </div>
        </div>
        <div class="yydc">
          <div class="yydc-xx">
            <span>*</span>
            <p class="bt">预 约 类 型：</p>
            <el-select class="yylx" v-model="postdata.OAType"  :disabled="userData.type == 4" placeholder="请选择">
              <el-option
                  v-for="item in yylx"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="yydc-xx">
            <span>*</span>
            <p class="bt">预约手术室：</p>
            <el-select class="yylx" :disabled="userData.type == 4"  v-model="postdata.OADeptId"  placeholder="请选择">
              <el-option
                  v-for="item in yyksop"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="yydc-xx" style="align-items: flex-start;">
            <span>*</span>
            <p class="bt">拟施手术：</p>
            <div style="padding-left: 10px;">
              <div class="yydc-yb">
                <p @click="teb('ssxzod')" class="dg"><i v-if="ssxzod" class="el-icon-check"></i></p>
                <div>OD</div>
                <el-cascader class="ybxz" :options="options" v-model="ssvalOD" :disabled="!ssxzod || userData.type>1" laceholder="请选择" :show-all-levels="true"></el-cascader>
<!--                <el-select class="ybxz" v-model="ssvalOD"  :disabled="!ssxzod  || userData.type>1" placeholder="请选择">-->
<!--                  <el-option-->
<!--                      v-for="item in options"-->
<!--                      :key="item.value"-->
<!--                      :label="item.label"-->
<!--                      :value="item.value">-->
<!--                  </el-option>-->
<!--                </el-select>-->
              </div>
              <div class="yydc-yb">
                <p  @click="teb('ssxzos')" class="dg"><i v-if="ssxzos" class="el-icon-check"></i></p>
                <div>OS</div>
                <el-cascader class="ybxz" :options="options" v-model="ssvalOS" :disabled="!ssxzos|| userData.type>1" laceholder="请选择" ></el-cascader>
<!--                <el-select class="ybxz" v-model="ssvalOS" :disabled="!ssxzos  || userData.type>1"    placeholder="请选择">-->
<!--                  <el-option-->
<!--                      v-for="item in options"-->
<!--                      :key="item.value"-->
<!--                      :label="item.label"-->
<!--                      :value="item.value">-->
<!--                  </el-option>-->
<!--                </el-select>-->
              </div>
              <div class="yydc-yb">
                <p  @click="teb('ssxzou')" class="dg"><i v-if="ssxzou" class="el-icon-check"></i></p>
                <div>OU</div>
                <el-cascader class="ybxz" :options="options" v-model="ssvalOU" :disabled="!ssxzou|| userData.type>1" laceholder="请选择" ></el-cascader>
<!--                <el-select class="ybxz" v-model="ssvalOU" :disabled="!ssxzou || userData.type>1"   placeholder="请选择">-->
<!--                  <el-option-->
<!--                      v-for="item in options"-->
<!--                      :key="item.value"-->
<!--                      :label="item.label"-->
<!--                      :value="item.value">-->
<!--                  </el-option>-->
<!--                </el-select>-->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="right-top">
          <p class="sjt">预约时间：</p>
          <el-date-picker
              :disabled="userData.type == 4"
              class="sjkj"
              size="small"
              v-model="time"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
          <div class="xxq" v-for="(item, idx) in weekarr" :class="weekidx==idx?'xzxxq':''" @click="tebweek(item, idx)">
            <p>{{item.time}}</p>
            <p>{{item.week}}</p>
          </div>
        </div>

        <div class="right-db">
          <div class="right-left">
            <el-input v-model="exval" size="small" placeholder="请输入医生">
              <el-button @click="ssys" slot="append" size="small" icon="el-icon-search"></el-button>
            </el-input>
            <div style="height: 338px;overflow-y: auto;">
              <p v-for="(item, idx) in exList" :class="exxz==idx?'yyxz':''" @click="tebys(item.ExpertId, idx)">
                <i style="font-size: 16px;" class="el-icon-s-custom"></i>
                <span>{{item.ExpertName}}</span>
              </p>
            </div>
          </div>

          <div class="right-m">
            <p v-for="(item, idx) in phList" :class="phxz==idx?'wsxz':''" @click="tebsxw(idx)">{{item.zwanem}}(已约：{{item.yys}})</p>
          </div>

          <div class="right-last">
            <p v-for="(item, idx) in phList[phxz].ph" @click="xzph(idx, item.isyy)" :class="[phnum==idx?'phxz':'', item.isyy?'yjyy':'']">{{item.bh}}号</p>
          </div>
        </div>
      </div>
    </div>


    <div class="yynr" v-if="userData.type != 5 && userData.type != 1 ">
      <p>
        <span>排程时间：</span>
        <el-time-picker
            :disabled="userData.type == 4"
            style="width: 150px"
            v-model="postdata.OATime"
            format="HH:mm:ss"
            value-format="HH:mm:ss"
            :picker-options="{
            // selectableRange: '18:30:00 - 20:30:00'
          }"
            placeholder="任意时间点">
        </el-time-picker>
      </p>
      <p>
        <span>核查项：</span>
        <el-checkbox-group v-model="hcxList" :disabled="userData.type == 4">
          <el-checkbox label="OAIsImmunity">免疫结果</el-checkbox>
          <el-checkbox label="OAIsDrug">药水</el-checkbox>
          <el-checkbox label="OAIsBloodDraw">血单</el-checkbox>
          <el-checkbox label="OAIsContactglass">隐形眼镜</el-checkbox>
        </el-checkbox-group>
      </p>
      <el-button type="primary" v-if="userData.type === 2 || userData.type === 3" @click="szzt('doing')" size="small"  style="height: 32px;">开始手术</el-button>
      <el-button type="primary" v-if="userData.type === 4" size="small" @click="szzt('finish')" style="height: 32px;">结束手术</el-button>

    </div>

    <div class="tj">
      <el-button type="primary" size="small" v-if="userData.type != 4" @click="save">提交</el-button>
      <el-button type="info" @click="gb" size="small">关闭</el-button>
    </div>
  </div>
</template>

<script>
import pf from "../../publicFn/baseFn";
export default {
  name: "surgeryAppointment",
  data() {
    return {
      hcxList: [],
      csdata: {},
      timeSK: '',
      value: '',
      exval:'',
      value1: '',
      value2: '',
      options: [
        {
          value: '晶体植入手术',
          label: '晶体植入手术',
          children: [
            {
              value: 'PRL',
              label: 'PRL'
            },
            {
              value: 'ICL',
              label: 'ICL'
            },
            {
              value: 'TICL',
              label: 'TICL'
            }
          ]
        },
        {
          value: '角膜激光手术',
          label: '角膜激光手术',
          children: [
            {
              value: '德国鹰视PTK',
              label: '德国鹰视PTK'
            },
            {
              value: '精雕飞秒+睛春clear+Q',
              label: '精雕飞秒+睛春clear+Q'
            },
            {
              value: '普通飞秒激光',
              label: '普通飞秒激光'
            },

            {
              value: '超薄flap+lasik+Q',
              label: '超薄flap+lasik+Q'
            },

            {
              value: '超薄普通飞秒',
              label: '超薄普通飞秒'
            },
            {
              value: '二次增效手术',
              label: '二次增效手术'
            },
            {
              value: '精雕飞秒+Q',
              label: '精雕飞秒+Q'
            },
            {
              value: '蔡司全飞秒SMILE（薄帽）',
              label: '蔡司全飞秒SMILE（薄帽）'
            },
            {
              value: '德国鹰视PTK+Q传统LASEK',
              label: '德国鹰视PTK+Q传统LASEK'
            },
            {
              value: '蔡司全飞秒SMILE',
              label: '蔡司全飞秒SMILE'
            },
            {
              value: '精雕飞秒+睛春clear',
              label: '精雕飞秒+睛春clear'
            },
            {
              value: '全飞秒+散光自旋补偿',
              label: '全飞秒+散光自旋补偿'
            }
          ]
        },
        {
          value: '白内障手术',
          label: '白内障手术',
          children: [
            {
              value: 'PHACO+IOL植入术',
              label: 'PHACO+IOL植入术'
            }
          ]
        },
      ],
      yyksop: [
        {
          label: '科室一',
          value: '科室一'
        },
        {
          label: '科室二',
          value: '科室二'
        },
      ],
      yyksval: '',
      today: '',
      yylx: [
        {
          label: '晶体植入手术',
          value: '晶体植入手术'
        },
        {
          label: '屈光激光手术',
          value: '屈光激光手术'
        },
        {
          label: '白内障手术',
          value: '白内障手术'
        }
      ],
      yylxval: '',
      time: '',
      time2: '',
      postdata: {
        OAIsCheckin: '',
        OAIsImmunity: '',
        OAIsDrug: '',
        OAIsBloodDraw: '',
        OAIsContactglass: '',
        ExpertName: '',
        OACompanyId: '',
        OAShopId: '',
        OAChooseId: '',
        OACustomerId: '',
        OAAddExpertId: '',
        OAExpertId: '',
        OACOrderId: '',
        OAEyes: '',
        OAType: '',
        OAODOperationProductId: '',
        OAODOperationName: '',
        OAOSOperationProductId: '',
        OAOSOperationName: '',
        OAOUOperationProductId: '',
        OAOUOperationName: '',
        OADate: '',
        OATime: '00:00:00',
        OAAMPM: '',
        OANumber: '',
        OAState: 'expected',
        OARemark: '',
        OADeptId: ''
      },

      ssvalOD: '',
      ssvalOS: '',
      ssvalOU: '',
      ssop: [],

      weekarr: [],
      weekidx: -1,
      ssxzos: false,
      ssxzod: false,
      ssxzou: false,

      exList: [],
      itemexList: [],
      exxz: 0,

      phList: [{
        ph: [{}]
      }],
      phxz: 0,

      phnum: 0,

    }
  },

  props: {
    userData: {}
  },

  watch: {
    'time': function (n, o) {
      if (this.exList.length) {
        this.weekidx = -1
        this.getswxw(this.exList[this.exxz].ExpertId, n)
      }
    },
    'hcxList': {
      handler(n, o) {
        this.postdata.OAIsImmunity = n.some((val) => {return val==='OAIsImmunity'})?1:0
        this.postdata.OAIsDrug = n.some((val) => {return val==='OAIsDrug'})?1:0
        this.postdata.OAIsBloodDraw = n.some((val) => {return val==='OAIsBloodDraw'})?1:0
        this.postdata.OAIsContactglass = n.some((val) => {return val==='OAIsContactglass'})?1:0
      },
      deep: true
    }
  },

  created() {
    this.today = pf.getTime(0)
    this.csdata = JSON.parse(JSON.stringify(this.$store.state.users.CsUser))


    this.init()

  },
  methods: {
    gb() {
      this.$emit("close", false)
    },
    init() {
      let arr = []
      for (let i = 0; i < 4; i++) {
        arr.push({
          time: pf.getYR(i),
          week: pf.getWeek(i)
        })
      }
      this.weekarr = arr
      arr = null

      this.time = pf.getTime(0)


      this._api.expertClinic.getDepartment()
      .then(res => {
        // console.log(res)
        let arr = []
        for (let i = 0; i < res.GetListResult.length; i++) {
          arr.push({
            value: res.GetListResult[i].DeptId,
            label: res.GetListResult[i].DeptName
          })
        }
        //this.yyksop = [...arr]
      })

      this._api.expertClinic.getODOS()
          .then(res => {
            // console.log(res)
            let arr = []
            if (res.GetSimpleListResult && res.GetSimpleListResult.length) {
              for (let i = 0; i < res.GetSimpleListResult.length; i++) {
                arr.push({
                  value: res.GetSimpleListResult[i].ProductId,
                  label: res.GetSimpleListResult[i].ProductName
                })
              }
            }
            //this.options = [...arr]
            arr = null
          })


      this._api.userCurdApi.getEmployeesList('', 1)
          .then(res => {
            // console.log(res)
            if (res.GetListResult && res.GetListResult.length>0) {
              this.exList = [... res.GetListResult]
              this.itemexList = [... res.GetListResult]
              return this.exList[0].ExpertId
            }
            return 0
          })
          .then(resp => {
            if (!resp) return
            this.getswxw(resp, this.today)

            this._api.expertClinic.OA_Get(this.userData.chooid)
                .then(res => {
                  console.log(res)
                  if (res.Result && res.ReturnCode == 5000) {
                    this.postdata = JSON.parse(JSON.stringify(res.Result))
                    // console.log(this.postdata)
                    if (this.postdata.OAODOperationName) {
                      this.ssxzod = true
                      this.ssvalOD = this.postdata.OAODOperationName
                    }
                    if (this.postdata.OAOSOperationName) {
                      this.ssxzos = true
                      this.ssvalOS = this.postdata.OAOSOperationName
                    }
                    if (this.postdata.OAOUOperationName) {
                      this.ssxzou = true
                      this.ssvalOU = this.postdata.OAOUOperationName
                    }

                    this.time = this.postdata.OADate || pf.getTime(0)


                    if (this.postdata.OAAMPM == 'AM') {
                      this.phxz = 0
                    }

                    if (this.postdata.OAAMPM == 'PM') {
                      this.phxz = 1
                    }

                    if (this.postdata.OAAMPM == 'Nty' || this.postdata.OAAMPM =='NTY') {
                      this.phxz = 2
                    }

                    this.phnum = this.postdata.OANumber - 1

                    for (let i = 0; i < this.exList.length; i++) {
                      if (this.exList[i].ExpertId === this.postdata.OAExpertId) {
                        this.exxz = i
                      }
                    }

                    let arr = []
                    if (this.postdata.OAIsImmunity) arr.push('OAIsImmunity')
                    if (this.postdata.OAIsDrug) arr.push('OAIsDrug')
                    if (this.postdata.OAIsBloodDraw) arr.push('OAIsBloodDraw')
                    if (this.postdata.OAIsContactglass) arr.push('OAIsContactglass')
                    // console.log(this.postdata)
                    this.hcxList = [...arr]


                    this.postdata.OAODOperationProductId = ''
                    this.postdata.OAODOperationName = ''
                    this.postdata.OAOSOperationProductId = ''
                    this.postdata.OAOSOperationName = ''
                    this.postdata.OAOUOperationProductId = ''
                    this.postdata.OAOUOperationName = ''

                  }else {
                    let csid = this.userData.chooid || this.$store.state.physicianVisits.xzConsulting,
                        mdid = this.$store.state.bases.shop.ShopId,
                        custid = this.userData.csid || this.$store.state.users.CsUser.CustomerId,
                        exidp = this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:'',
                        companyid = this.$store.state.users.user.Company.CompanyId,
                        uids = this.$store.state.users.user.UserId;
                    this.postdata.OAAddExpertId = exidp
                    this.postdata.OAChooseId = csid
                    this.postdata.OACustomerId = custid
                    this.postdata.OACompanyId = companyid
                    this.postdata.OAShopId = mdid
                  }
                })
          })



      // return

    },

    teb(key) {

      if (this.userData.type == 2) return;

      if (key == 'ssxzou' && this.ssxzou == false) {
        this.ssxzou = true
        this.ssxzod = false
        this.ssxzos = false
        this.ssvalOD = ''
        this.ssvalOS = ''


        this.postdata.OAODOperationProductId = ''
        this.postdata.OAODOperationName = ''
        this.postdata.OAOSOperationProductId = ''
        this.postdata.OAOSOperationName = ''

      }else {
        this[key] = !this[key]
        this.ssxzou = false
        this.ssvalOU = ''
        if (!this.ssxzos) {
          this.ssvalOS = ''
          this.postdata.OAOSOperationProductId = ''
          this.postdata.OAOSOperationName = ''
        }
        if (!this.ssxzod) {
          this.ssvalOD = ''
          this.postdata.OAODOperationProductId = ''
          this.postdata.OAODOperationName = ''
        }
      }
    },

    getswxw(exid, time) {
      let arr = []
      this._api.expertClinic.GetNumberList(exid, time, time)
          .then(res => {
            // console.log(res)
            if (res.Result && res.ReturnCode === 5000) {
              let yys = 0
              arr.push({
                name: 'AM',
                zwanem: '上午',
                ph: [],
                yys: 0
              })
              for (let key in  res.Result.AM) {
                arr[0].ph.push({
                  bh: key,
                  isyy: res.Result.AM[key]
                })
                if (res.Result.AM[key]) yys += 1
              }
              arr[0].yys = yys
              yys = 0

              arr.push({
                name: 'PM',
                ph: [],
                zwanem: '下午',
                yys: 0
              })
              for (let key in  res.Result.PM) {
                arr[1].ph.push({
                  bh: key,
                  isyy: res.Result.PM[key]
                })
                if (res.Result.PM[key]) yys += 1
              }
              arr[1].yys = yys
              yys = 0

              arr.push({
                name: 'Nty',
                zwanem: '晚上',
                ph: [],
                yys: 0
              })
              for (let key in  res.Result.Nty) {
                arr[2].ph.push({
                  bh: key,
                  isyy: res.Result.Nty[key]
                })
                if (res.Result.Nty[key]) yys += 1
              }
              arr[2].yys = yys
              yys = 0


              this.phList = [...arr]
            }
            else {
              this.phList = []
            }
          })
    },

    xzph(idx, isyy) {
      if (this.userData.type == 4) return
      if (isyy) {
        return this.$alert('此号源已有预约！', '提示')
      }
      this.phnum = idx
      // console.log(isyy)
    },

    tebsxw(idx) {
      if (this.userData.type == 4) return;
      this.phxz = idx;
      this.phnum = 0
    },

    ssys() {
      let arr = []
      arr = this.itemexList.filter((item) => {
        return item.ExpertName.indexOf(this.exval) > -1
      })
      this.exList = [...arr]
      arr = null;
    },

    tebweek(item,idx) {
      if (this.userData.type == 4) return
      if (this.exList.length > 0) {
        let time = new Date().getFullYear() + '-' + item.time
        this.weekidx = idx
        this.getswxw(this.exList[this.exxz].ExpertId, time)
      }
    },

    save(e, zhuangtai='expected') {

      if (!this.postdata.OAType)  return this.$alert('请选择预约类型！', '提示')

      if (!this.ssxzos && !this.ssxzod && !this.ssxzou)  return this.$alert('请选择实施手术！', '提示')


      if (this.ssxzos) {
        if (!this.ssvalOS) return this.$alert('请选择OS手术！', '提示')
      }

      if (this.ssxzod) {
        if (!this.ssvalOD) return this.$alert('请选择OD手术！', '提示')
      }

      if (this.ssxzou) {
        if (!this.ssvalOU) return this.$alert('请选择OU手术！', '提示')
      }

      if ( this.phnum < 0) {
       return  this.$alert('请选择排号！', '提示')
      }

      let time = ''
      this.weekidx>0?time = `${new Date().getFullYear()}-${this.weekarr[this.weekidx].time}`:time=this.time

      this.postdata.OAAMPM = this.phList[this.phxz].name
      this.postdata.OAExpertId = this.exList[this.exxz].ExpertId
      this.postdata.OAAddExpertId = this.$store.state.users.employees.ExpertId
      this.postdata.OADate = time
      this.postdata.OANumber = this.phList[this.phxz].ph[this.phnum].bh

      this.postdata.OAState = zhuangtai


      // for (let i = 0; i < this.options.length; i++) {
      //   if (this.options[i].value === this.ssvalOD) {
      //     // this.postdata.OAODOperationProductId = this.ssvalOD
      //     this.postdata.OAODOperationName = this.options[i].label
      //   }
      //   if (this.options[i].value === this.ssvalOS) {
      //     // this.postdata.OAOSOperationProductId = this.ssvalOS
      //     this.postdata.OAOSOperationName = this.options[i].label
      //   }
      //   if (this.options[i].value === this.ssvalOU) {
      //     // this.postdata.OAOUOperationProductId = this.ssvalOU
      //     this.postdata.OAOUOperationName = this.options[i].label
      //   }
      // }


      if (typeof this.ssvalOD == 'object') {
        this.postdata.OAODOperationName = this.ssvalOD&&this.ssvalOD.length?this.ssvalOD[1]:''
      }else {
        this.postdata.OAODOperationName = this.ssvalOD
      }

      if (typeof this.ssvalOS == 'object') {
        this.postdata.OAOSOperationName = this.ssvalOS&&this.ssvalOS.length?this.ssvalOS[1]:''
      }else {
        this.postdata.OAOSOperationName = this.ssvalOS
      }

      if (typeof this.ssvalOU == 'object') {
        this.postdata.OAOUOperationName = this.ssvalOU&&this.ssvalOU.length?this.ssvalOU[1]:''
      }else {
        this.postdata.OAOUOperationName = this.ssvalOU
      }

      let log = this.$loading({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '保存中...'
      })

      // let url = 'OA_Insert'
      // if (this.postdata.OAId) url = 'OA_Update'
      this._http.post(this._getUrl('OA_Update'), {
        "token": {
          "TimeSpan": 1616479500,
          "sToken": "4D6A372AC044AC7A41331B9141F46536"
        },
        o: this.postdata
      })
      .then(res => {
        // console.log(res)
        log.close()
        if (res.ReturnCode == 5000) {
          this.phList[this.phxz].ph[this.phnum].isyy = true
          this.$alert('提交成功！', '提示')
        }else {
          this.$alert('提交失败！请稍后再试', '提示')
        }
      })

    },

    tebys(exid, idx) {
      if (this.userData.type == 4) return
      this.exxz = idx
      let time = ''
      this.weekidx>0?time = `${new Date().getFullYear()}-${this.weekarr[this.weekidx].time}`:time=this.time
      this.getswxw(exid, time)
    },

    szzt(zt) {
      // this.postdata.OAState = zt

      this.save(window,zt)

    }
  }
}
</script>

<style scoped lang="scss">
  .surgeryAppointment {
    padding-bottom: 15px;
    width: 900px;
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    pointer-events: auto;
  }
  .seeing-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
  }
  .box {
    width: 100%;
    display: flex;
  }
  .title {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    span {font-size: 20px}
    i {
      font-size: 30px;
    }
  }
  .left {
    border: 1px solid #DFDFDF;
    width: 50%;
    background: #F7F7F7;
    padding-bottom: 20px;
    .left-a {
      display: flex;
      justify-content: center;
      padding: 10px;
      .xm {
        width: 120px;
        background: #F7F7F7;
      }
      .bh {
        width: 220px;
        background: #F7F7F7;
        margin: 0 10px;
      }
    }
    .yhxx {
      display: flex;
      font-size: 13px;
      justify-content: center;
      padding: 10px 0;
      border-bottom: 1px solid #DFDFDF;
      img {
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      p {
        padding: 5px 0;
        text-align: left;
        span {
          display: inline-block;
          width: 5em;
          text-align: right;
          font-weight: bold;
        }
      }
    }
    .yydc {
      padding-left: 20px;
      .yydc-xx {
        display: flex;
        align-items: center;
        justify-content: start;
        padding-top: 10px;
        span {color: #ff0101;}
        .yydc-yb {
          display: flex;
          align-items: center;
          padding-bottom: 7px;
          p {
            margin-right: 10px;
            border: 1px solid #7f7e7e;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            color: #0000e5;
          }
          div {padding-right: 10px;}
          .ybxz {width: 260px;}
        }
        .yylx {width: 333px}
        .bt {
          font-weight: bold;
        }
      }
    }
  }
  .right {
    border: 1px solid #DFDFDF;
    width: 50%;
    background: #F7F7F7;
    padding-bottom: 20px;
    .right-top {
      display: flex;
      align-items: center;
      padding: 10px 0;
      .sjt {
        font-weight: bold;
        padding-left: 10px;
      }
      .sjkj {width: 130px;}
      .xxq {
        cursor: pointer;
        border: 1px solid #117E6C;
        font-size: 12px;
        padding: 5px;
        border-radius: 5px;
        margin-left: 10px;
        &:hover{
          background-color: #D3EDE8;
          border-color: #117E6C;
          color: #117E6C;
        }
      }
      .xzxxq {
        background-color: #D3EDE8;
        border-color: #117E6C;
        color: #117E6C;
      }
    }
    .right-db {
      border-top: 1px solid #DFDFDF;
      display: flex;
    }
    .right-left {
      width: 161px;
      height: 370px;
      background: #F6F8FA;
      p {
        border-radius: 2px;
        border: 1px solid #DFDFDF;
        cursor: pointer;
        padding: 8px 0;
        text-align: left;
        i {margin-left: 15px; margin-right: 5px;}
        margin-bottom: 5px;
        &:hover{
          background: #EDFFFC;
          color: #17A085;
          border: 1px solid #17A085;
        }
      }
      .yyxz {
        background: #EDFFFC;
        color: #17A085;
        border: 1px solid #17A085;
      }
    }
    .right-m {
      width: 148px;
      height: 370px;
      background: rgb(236, 236, 236);
      p {
        cursor: pointer;
        padding: 8px 0;
        &:hover {
          background: #FFFFFF;
          color: #17A085;
        }
      }
      .wsxz {
        background: #FFFFFF;
        color: #17A085;
      }
    }
    .right-last {
      width: 137px;
      height: 370px;
      overflow-y: auto;
      p {
        border-bottom: 1px solid #D9D9D9;
        text-align: center;
        cursor: pointer;
        color: #0a0a0a;
        padding: 10px 0;
        &:hover {
          background: #D3EDE8;
          color: #17A085;
          border: 1px solid #17A085;
        }
      }
      .phxz {
        background: #D3EDE8;
        color: #17A085;
        border: 1px solid #17A085;
      }
      .yjyy {
        background: #eaeaea;
        cursor: not-allowed;
        color: #b6b6b6;
      }
    }
  }
  .tj {
    text-align: right;
    padding-right: 5px;
    position: absolute;
    bottom: 5px;
    right: 0;
  }

  .yynr {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-left: 20px;
    p {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  }
</style>
